import React from 'react'
import "./Footer.css"
import { FaHome, FaPhone, FaMailBulk, FaLinkedin} from "react-icons/fa"
const Footer = () => {
    return (
        <div className='footer'>
            <div className='footer-container'>
                <div className='left'>
                    <div className='left-content'>
                        <FaHome size={20} style={{ color: "white", marginRight: "2rem" }} />
                        <p>Luján de Cuyo, Mendoza, Argentina.</p>
                        
                    </div>
                    <div className='left-content'>
                        <h4>
                            <FaPhone size={20} style={{ color: "white", marginRight: "2rem" }} />
                            +54 9 2616700206 - +54 9 2616993840
                        </h4>
                    </div>
                    <div className='left-content'>
                    <h4>
                            <FaMailBulk size={20} style={{ color: "white", marginRight: "2rem" }} />
                            info@itsolucionesintegrales.com
                        </h4>
                    </div>

                </div>
                <div className='right'>
                    <div className='right-content'>
                    <h4>
                        IT Soluciones Integrales
                    </h4>
                    <p>
                        Construyendo soluciones a medida de cada cliente.
                    </p>
                        <div className='social'>
                        <a href=''><FaLinkedin size={30} style={{ color: "white", marginRight: "1rem" }} /></a>
                        </div>
                        
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default Footer
