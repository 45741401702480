import React from 'react'
import "./Hero.css";
const Hero = ({ imgSrc, imgSrcLogo }) => {
    return (
        <div className='hero'>
            <img src={imgSrc} alt="IT Soluciones Integrales" className="hero-img" />
            <img src={imgSrcLogo} alt="logo" className="hero-logo" />
            <h1 className='hero-title'>Construyendo soluciones a medida de cada cliente</h1>
        </div>
    );
};

export default Hero
