import "./Projects.css"
import React from 'react'

import { useInView } from 'react-intersection-observer';
const Projects = ({ imgSrc, title, subtitle, flipped }) => {
    const { ref, inView, entry } = useInView({
        /* Optional options */
        threshold: 0.4,
    });


    const renderContent = () => {
        
        if (!flipped) {
            return <>
                <img src={imgSrc} className="projects-img" />
                <div className='projects-content'>
                    <h1 className='projects-title'>{title}</h1>
                    <p>{subtitle}</p>
                </div>
            </>;
        }
        else {
            return <>
                <div className='projects-content'>
                    <h1 className='projects-title'>{title}</h1>
                    <p>{subtitle}</p>
                </div>
                <img src={imgSrc} className="projects-img" />

            </>;
        }
    }
    return (
        
        <div className={inView ? "projects projects--zoom" : "projects"} ref={ref}>
            
            {renderContent()}
            
        </div>
    )
}

export default Projects

