import React from 'react'
import "./Slider.css"
import { useInView } from 'react-intersection-observer';
const Slider = ({ imgSrc, title, subtitle, flipped }) => {
    const { ref, inView, entry } = useInView({
        /* Optional options */
        threshold: 0.4,
    });


    const renderContent = () => {
        if (!flipped) {
            return <>
                <img src={imgSrc} className="slider-img" />
                <div className='slider-content'>
                    <h1 className='slider-title'>{title}</h1>
                    <p>{subtitle}</p>
                </div>
            </>;
        }
        else {
            return <>
                <div className='slider-content'>
                    <h1 className='slider-title'>{title}</h1>
                    <p>{subtitle}</p>
                </div>
                <img src={imgSrc} className="slider-img" />

            </>;
        }
    }
    return (
        <div className={inView ? "slider slider--zoom" : "slider"} ref={ref}>
            {renderContent()}
        </div>
    )
}

export default Slider
