import React from 'react'
import "./Services.css"
import { useInView } from 'react-intersection-observer';
const Services = () => {
    const { ref, inView, entry } = useInView({
        /* Optional options */
        threshold: 0.4,
    });
    return (
        <div className={inView ? "services services--zoom" : "services"} ref={ref}>
            <div className='services-container'>
                <div className='service-item'>
                    <h2 className='service-title'>Ingenieria de detalle</h2>
                    <p className='service-text'>Elaborar la ingeniería de detalle a partir de una ingeniería conceptual o básica
                        Soluciones innovadoras ante diferentes desafíos que se presentan en el desarrollo de la ingeniería
                        y de la obra.
                        Maquetizado en 3D de todas las instalaciones para detectar interferencias, tener un control sobre
                        las diferentes especialidades y elaborar una correcta documentación del proyecto
                    </p>
                </div>
                <div className='service-item'>
                    <h2 className='service-title'> Ingenieria Civil</h2>
                    <p className='service-text'>Diseñar estructuras metálicas livianas y pesadas.
                        Fundaciones de edificios.
                        Estructuras premoldeadas.
                        Fundaciones de equipos, etc.</p>
                </div>
                <div className='service-item'>
                    <h2 className='service-title'>Piping</h2>
                    <p className='service-text'>Diseño sistemas de tuberías metálicas como plásticas, contamos con amplia experiencia que
                        comprende desde el cálculo hidráulico del sistema de tuberías, y su análisis ante los esfuerzos
                        existentes en el proceso (presión, cargas térmicas, esfuerzos transitorios, etc.).</p>
                </div>
                <div className='service-item'>
                    <h2 className='service-title'>Mecanica y equipos</h2>
                    <p className='service-text'>Diseño de equipos: recipientes sometidos a presión (ASME), tanques de almacenamiento (API),
                        equipos para vacío, y realizamos la selección de bombas para impulsar fluidos, las válvulas según
                        cada aplicación y cualquier accesorio requerido por el sistema</p>
                </div>
                <div className='service-item'>
                    <h2 className='service-title'>Electricidad e Instrumentacion</h2>
                    <p className='service-text'>Amplia experiencia en el desarrollo de la ingeniería de electricidad e instrumentación: desarrollo de
                        tableros de potencia, unifilares, tendidos de cables, hojas de datos de instrumentos, cálculos de
                        cortocircuitos, luminotécnica, selección de protección ante la corrosión, etc.</p>
                </div>
                <div className='service-item'>
                    <h2 className='service-title'>Procesos</h2>
                    <p className='service-text'>Contamos con ingenieros químicos capaces de diseñar equipos e instalaciones en base a la
                        necesidad del cliente.
                        Manejo de softwares específicos que facilitan el análisis multivariables.</p>
                </div>
            </div>
        </div>
    )
}

export default Services
