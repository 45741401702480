import './App.css';
import hero_img from "./assets/alain-pham-P_qvsF7Yodw-unsplash.jpg"
import hero_logo from "./assets/logo - IT Soluciones Integrales-03.png"
import first_img from "./assets/thisisengineering-raeng-xYCBw1uIP_M-unsplash.jpg"
import second_img from "./assets/scott-blake-x-ghf9LjrVg-unsplash.jpg"
import project1 from "./assets/plantapta.jpg"
import project2 from "./assets/project2.png"
import project3 from "./assets/project3.png"
import project4 from "./assets/project4.jpg"
import project5 from "./assets/project5.jpg"
import project6 from "./assets/project6.jpg"
import Hero from "./components/Hero"
import Slider from "./components/Slider"
import Navbar from './components/Navbar';
import Services from './components/Services';
import Footer from './components/Footer';
import Projects from './components/Projects';
const navbarLinks = [
  { url: "/", title: "Inicio" },
  { url: "#services", title: "Servicios" },
  { url: "#projects", title: "Proyectos" },
  { url: "#footer", title: "Contacto" },
]

function App() {
  var sub = "Honestidad y transparencia.\n Eficiencia.\n Confianza.\n Seguridad y garantia.";
  return (
    <div className="App">
      <Navbar navbarLinks={navbarLinks}/>
      <div id="hero">
      <Hero imgSrc={hero_img} imgSrcLogo={hero_logo}/>
      </div>
      
      <Slider imgSrc={first_img} title={"¿Quiénes somos?"} subtitle={"Somos una empresa comprometida con el cliente, trabajamos cada día para poder brindar trabajos de calidad, de la mano de excelentes profesionales, asi generamos soluciones de forma eficaz a los diferentes proyectos. "}
      />
       <Slider imgSrc={second_img} title={"Nuestros valores"} subtitle={sub}
       flipped={true}
      />
      <div id="services">
      <h1 class="services-title-h1">Servicios</h1>
      <Services/>
      </div>
      <div id="projects">
        <h1 class="projects-title-h1">Nuestros proyectos</h1>
      <Projects  imgSrc={project1} title={"Neuquén. Planta PTA DB"} subtitle={"Ampliación de planta con 3 bombas nuevas y 2 filtros.	Diseño completamente modelado en 3D para detectar interferencias y diseño preciso. Se realizado la documentación apta para construcción. "}/>
      <Projects  imgSrc={project2} title={"Calculo de stress analysis"} subtitle={"Verificación de tensiones y deformaciones sobre tuberías de Ø24” y Ø18”." } flipped={true}/>
      <Projects  imgSrc={project3} title={"Tanque Skimmer 320 m3 – Yacimiento Vizcacheras"} subtitle={"Tanque para contener petróleo y agua. Diseño del equipo bajo norma API 650. El equipo se modelo íntegramente en Solidworks, y luego se realizó toda la planimetría necesaria para una correcta fabricación." }/>
      <Projects  imgSrc={project4} title={"Neuquén. Planta PIA CHUS"} subtitle={"Ampliación mediante una bomba adicional, un tanque nuevo y un tanque proyectado. Diseño completamente modelado en 3D para detectar interferencias y diseño preciso. Se realizado la documentación apta para construcción. " } flipped={true}/>
      <Projects  imgSrc={project5} title={"Diseño Tabiques Premoldeados"} subtitle={"Diseño de muro de contención de tanque de petróleo.El pretil fue fabricado en módulos transportables. Se realizó el cálculo, planimetría y dirección técnica. " }/>
      <Projects  imgSrc={project6} title={"Soporte metálico de Transformadores de intensidad"} subtitle={"Se realizó el dimensionamiento y verificación mediante software computacional. Fabricación de soporte en taller. Inspecciones mediante END de tintas penetrantes. " } flipped={true}/>
      </div>
      <div id="footer">
      <Footer/>
      </div>
    </div>
  );
}

export default App;
