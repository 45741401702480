import React, { useState } from 'react'
import "./Navbar.css"
import { FiMenu, FiX } from "react-icons/fi"
import Logo from "../assets/logo - IT Soluciones Integrales-04.png"

const Navbar = ({ navbarLinks }) => {

    const [menuClicked, setMenuClicked] = useState(true);

    const toggleMenuClick = () => {
        setMenuClicked(!menuClicked);
    }
    return (
        <nav className='navbar'>
            <a href='/'> <img src={Logo} className='navbar-logo'></img></a>
            {menuClicked ? (
            <FiMenu size={25} className="navbar-menu" onClick={toggleMenuClick}/>
            ) : (
                (<FiX size={25} className="navbar-menu" onClick={toggleMenuClick}/>) 
            )}
            <ul className={menuClicked ? "navbar-list" : "navbar-list navbar-list--active"}>
                {navbarLinks.map(item => {
                    return (<li className='navbar-item' key={item.title}>
                        <a className='navbar-link' href={item.url}>
                            {item.title}
                        </a>
                    </li>)
                })}
            </ul>
        </nav>
    )
}

export default Navbar
